// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
import "activeadmin_addons"

import "@fortawesome/fontawesome-free/css/all.css";
import "arctic_admin";

import "controllers"

import * as ActiveStorage from "@rails/activestorage"
import "trix"
import "@rails/actiontext"

import "../inputs/easymde_editor_input"

ActiveStorage.start()

jQuery.datetimepicker.setLocale("ru")
